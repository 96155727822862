import axios from 'modules/api/axios';
import {AppConfigService} from 'modules/appConfig/AppConfigService';
import {
    SessionApi,
    NotificationEmailApi,
    RealEstateLocationApi,
    RealEstateStatsUserApi,
    RealEstateAgentListingApi,
    RealEstateAgencyDetailApi,
    RealEstateAgencyListingApi,
    RealEstateAgentLandingPageApi,
    RealEstateAgencyLandingPageApi,
    AdvertisementProjectsSearchMapApi as DevProjectsSearchMapApi,
    AdvertisementDetailApi,
    AdvertisementListingApi,
    SearchFilterApi,
    LocationApi,
    AdvertisementAmenitiesApi,
    DevProjectExclusiveProjectListingApi,
    RealEstateAgentDetailApi,
    RealEstateAdvertisementDetailListingApi,
    AdvertisementComparisonApi,
    AdvertisementFavouriteApi,
    RelationApi,
    HomePageDevProjectsApi,
    AdvertisementSearchAgentApi
} from 'modules/api/generated';

const sessionApi = new SessionApi(undefined, AppConfigService.getNehnutelnostiNestApiUrl(), axios);
const notificationEmailApi = new NotificationEmailApi(undefined, AppConfigService.getNehnutelnostiNestApiUrl(), axios);
const realEstateLocationApi = new RealEstateLocationApi(undefined, AppConfigService.getNehnutelnostiNestApiUrl(), axios);
const realEstateStatsUserApi = new RealEstateStatsUserApi(undefined, AppConfigService.getNehnutelnostiNestApiUrl(), axios);
const realEstateAgentDetailApi = new RealEstateAgentDetailApi(undefined, AppConfigService.getNehnutelnostiNestApiUrl(), axios);
const realEstateAgentListingApi = new RealEstateAgentListingApi(undefined, AppConfigService.getNehnutelnostiNestApiUrl(), axios);
const realEstateAgencyDetailApi = new RealEstateAgencyDetailApi(undefined, AppConfigService.getNehnutelnostiNestApiUrl(), axios);
const realEstateAgencyListingApi = new RealEstateAgencyListingApi(undefined, AppConfigService.getNehnutelnostiNestApiUrl(), axios);
const devProjectsSearchMapApi = new DevProjectsSearchMapApi(undefined, AppConfigService.getNehnutelnostiNestApiUrl(), axios);
const realEstateAgentLandingPageApi = new RealEstateAgentLandingPageApi(undefined, AppConfigService.getNehnutelnostiNestApiUrl(), axios);
const realEstateAgencyLandingPageApi = new RealEstateAgencyLandingPageApi(undefined, AppConfigService.getNehnutelnostiNestApiUrl(), axios);
const realEstateAdvertisementDetailListingApi = new RealEstateAdvertisementDetailListingApi(undefined, AppConfigService.getNehnutelnostiNestApiUrl(), axios);
const advertisementDetailApi = new AdvertisementDetailApi(undefined, AppConfigService.getNehnutelnostiNestApiUrl(), axios);
const advertisementListingApi = new AdvertisementListingApi(undefined, AppConfigService.getNehnutelnostiNestApiUrl(), axios);
const searchFilterApi = new SearchFilterApi(undefined, AppConfigService.getNehnutelnostiNestApiUrl(), axios);
const locationApi = new LocationApi(undefined, AppConfigService.getNehnutelnostiNestApiUrl(), axios);
const advertisementAmenitiesApi = new AdvertisementAmenitiesApi(undefined, AppConfigService.getNehnutelnostiNestApiUrl(), axios);
const advertisementFavoriteApi = new AdvertisementFavouriteApi(undefined, AppConfigService.getNehnutelnostiNestApiUrl(), axios);
const advertisementComparisonApi = new AdvertisementComparisonApi(undefined, AppConfigService.getNehnutelnostiNestApiUrl(), axios);
const devProjectExclusiveProjectListingApi = new DevProjectExclusiveProjectListingApi(undefined, AppConfigService.getNehnutelnostiNestApiUrl(), axios);
const relationApi = new RelationApi(undefined, AppConfigService.getNehnutelnostiNestApiUrl(), axios);
const homePageDevProjectsApi = new HomePageDevProjectsApi(undefined, AppConfigService.getNehnutelnostiNestApiUrl(), axios);
const searchAgentApi = new AdvertisementSearchAgentApi(undefined, AppConfigService.getNehnutelnostiNestApiUrl(), axios);

export {
    sessionApi,
    notificationEmailApi,
    devProjectsSearchMapApi,
    realEstateLocationApi,
    realEstateStatsUserApi,
    realEstateAgentListingApi,
    realEstateAgencyDetailApi,
    realEstateAgencyListingApi,
    realEstateAgentLandingPageApi,
    realEstateAgencyLandingPageApi,
    realEstateAdvertisementDetailListingApi,
    advertisementDetailApi,
    advertisementListingApi,
    locationApi,
    searchFilterApi,
    advertisementAmenitiesApi,
    devProjectExclusiveProjectListingApi,
    advertisementComparisonApi,
    realEstateAgentDetailApi,
    advertisementFavoriteApi,
    relationApi,
    homePageDevProjectsApi,
    searchAgentApi
};


