import axios from 'axios';
import { EType } from 'modules/environment/enums/EEnviromentType';
import { getEnviromentType } from 'modules/environment/getEnviromentType';
import {apiTokenName} from 'modules/session/constants/apiTokenName';
import { getCookieToken } from 'modules/session/helpers/getCookieToken';
import { GetServerSidePropsContext } from 'next';

let sessionToken: string;
const instance = axios.create();

export const forwardContext = (context: GetServerSidePropsContext): void => {
    sessionToken = getCookieToken(context.req.cookies);
    setSessionToken(sessionToken);
};

export const hasSessionToken = (): boolean => {
    return !!sessionToken;
};

export default instance;

const setSessionToken = (token: string) => {
    instance.interceptors.request.use(config => {
        return {
            ...config,
            headers: {
                ...config.headers,
                ...(token && {[apiTokenName]: token}),
            },
            withCredentials: false,
        };
    });
};


if (getEnviromentType() === EType.FRONT_END) {
    sessionToken = getCookieToken();
    setSessionToken(sessionToken);
}